<template>
  <div class="container">
    <div class="wizard-progress mb-4">
      <div class="d-flex justify-content-between my-3">
        <div v-for="step in steps" :key="step" class="text-center">
          <div :class="['circle', step <= currentStep ? 'filled' : '']">{{ step }}</div>
          <div>{{ stepTitles[step - 1] }}</div>
        </div>
      </div>
      <div class="progress" style="height: 20px;">
        <div class="progress-bar" role="progressbar" :style="{ width: progressPercentage + '%' }" aria-valuenow="25"
          aria-valuemin="0" aria-valuemax="100"></div>
      </div>
    </div>
    <div v-if="currentStep === 1">
      <h2 class="text-center mb-4">Passo 1: Carregar exemplo da base de dados</h2>
      <div class="row justify-content-center">
        <div class="col-lg-6">
          <div class="card">
            <div class="card-body">
              <form @submit.prevent="uploadFile">
                <div class="mb-3">
                  <label for="fileUpload" class="form-label">Selecionar ficheiro</label>
                  <input type="file" class="form-control" id="fileUpload" @change="handleFileUpload">
                </div>
                <button type="submit" class="btn btn-primary w-100">Carregar</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="currentStep === 2">
      <h2 class="text-center mb-4">Passo 2: Escolher colunas gerais</h2>
      <div class="row justify-content-center">
        <div class="col-md-6">
          <div class="card">
            <div class="card-body">
              <form @submit.prevent="sendGeneralColumns">
                <label for="patient_id" class="form-label">Processo do doente</label>
                <select class="form-select mb-3" v-model="generalSelections.patient_identifier_col" id="patient_id"
                  required>
                  <option value="" disabled>Sem coluna</option>
                  <option v-for="option in fileColumns" :key="option" :value="option">{{ option }}</option>
                </select>
                <label for="report_id" class="form-label">Número de amostra</label>
                <select class="form-select mb-3" v-model="generalSelections.report_identifier_col" id="report_id"
                  required>
                  <option value="" disabled>Sem coluna</option>
                  <option v-for="option in fileColumns" :key="option" :value="option">{{ option }}</option>
                </select>
                <label for="report_reception" class="form-label">Data de recepção</label>
                <select class="form-select mb-3" v-model="generalSelections.report_reception_col" id="report_reception"
                  required>
                  <option value="" disabled>Sem coluna</option>
                  <option v-for="option in fileColumns" :key="option" :value="option">{{ option }}</option>
                </select>
                <label for="report_issued" class="form-label">Data de resultado</label>
                <select class="form-select mb-3" v-model="generalSelections.report_issued_col" id="report_issued"
                  required>
                  <option value="" disabled>Sem coluna</option>
                  <option v-for="option in fileColumns" :key="option" :value="option">{{ option }}</option>
                </select>
                <label for="report_requester" class="form-label">Serviço requisitante</label>
                <select class="form-select mb-3" v-model="generalSelections.report_requester_col" id="report_requester"
                  required>
                  <option value="" disabled>Sem coluna</option>
                  <option v-for="option in fileColumns" :key="option" :value="option">{{ option }}</option>
                </select>
                <label for="species" class="form-label">Agente isolado</label>
                <select class="form-select mb-3" v-model="generalSelections.species_col" id="species" required>
                  <option value="" disabled>Sem coluna</option>
                  <option v-for="option in fileColumns" :key="option" :value="option">{{ option }}</option>
                </select>
                <label for="report_specimen" class="form-label">Tipo de amostra</label>
                <select class="form-select mb-3" v-model="generalSelections.report_specimen_col" id="report_specimen"
                  required>
                  <option value="" disabled>Sem coluna</option>
                  <option v-for="option in fileColumns" :key="option" :value="option">{{ option }}</option>
                </select>
                <label for="patient_sex" class="form-label">Sexo do doente</label>
                <select class="form-select mb-3" v-model="generalSelections.patient_gender_col" id="patient_sex">
                  <option value="">Sem coluna</option>
                  <option v-for="option in fileColumns" :key="option" :value="option">{{ option }}</option>
                </select>
                <label for="patient_birth" class="form-label">Data de nascimento do doente</label>
                <select class="form-select mb-3" v-model="generalSelections.patient_birth_col" id="patient_birth">
                  <option value="">Sem coluna</option>
                  <option v-for="option in fileColumns" :key="option" :value="option">{{ option }}</option>
                </select>
                <button type="submit" class="btn btn-primary w-100">Seguinte</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="currentStep === 3">
      <h2 class="text-center mb-4">Passo 3: Escolher colunas antimicrobianos</h2>
      <div class="row justify-content-center">
        <div class="col-md-6">
          <div class="card">
            <div class="card-body">
              <form @submit.prevent="sendAntimicrobialColumns">
                <div v-for="antimicrobial in antimicrobials" :key="antimicrobial.id" class="mb-3">
                  <label for="antimicrobial.id" class="form-label">{{ antimicrobial.name }}</label>
                  <select class="form-select" id="antimicrobial.id" v-model="antimicrobial.mapping">
                    <option value="">Sem coluna</option>
                    <option v-for="column in fileColumns" :key="column" :value="column">{{ column }}</option>
                  </select>
                </div>
                <button type="submit" class="btn btn-primary w-100">Seguinte</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="currentStep === 4">
      <h2 class="text-center mb-4">Passo 4: Codificação de variáveis</h2>
      <div class="row justify-content-center">
        <div class="col-md-6">
          <form @submit.prevent="sendMappings">
            <div class="card mb-3">
              <div class="card-header">
                TSA
              </div>
              <div class="card-body">
                <input type="text" class="form-control mt-2 mb-2" v-model="newSusceptibilityMapping"
                  placeholder="Adicionar nova opção">
                <button class="btn btn-primary mb-4" @click.prevent="addSusceptibilityMapping()">Adicionar opção</button>
                <div v-for="susceptibility in mappings.susceptibility" :key="susceptibility" class="mb-3">
                  <label for="susceptibility" class="form-label">{{ susceptibility.name }}</label>
                  <div v-for="option in new Set(mappings.susceptibility_col.concat(susceptibility.mapping))" :key="option"
                    id="specimen" class="form-check">
                    <input type="checkbox" class="form-check-input" :id="susceptibility - option" :value="option"
                      v-model="susceptibility.mapping" @change="toggleSusceptibilityMapping(option)">
                    <label class="form-check-label" :for="susceptibility - option">{{ option }}</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="card mb-3">
              <div class="card-header">
                Tipos de amostras
              </div>
              <div class="card-body">
                <input type="text" class="form-control mt-2 mb-2" v-model="newSpecimenMapping"
                  placeholder="Adicionar nova opção">
                <button class="btn btn-primary mb-4" @click.prevent="addSpecimenMapping()">Adicionar opção</button>
                <div v-for="specimen in mappings.specimen" :key="specimen" class="mb-3">
                  <label for="specimen" class="form-label">{{ specimen.specimen }}</label>
                  <div v-for="option in new Set(mappings.specimen_col.concat(specimen.mapping))" :key="option"
                    id="specimen" class="form-check">
                    <input type="checkbox" class="form-check-input" :id="specimen - option" :value="option"
                      v-model="specimen.mapping" @change="toggleSpecimenMapping(option)">
                    <label class="form-check-label" :for="specimen - option">{{ option }}</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="card mb-3">
              <div class="card-header">
                Bacterias
              </div>
              <div class="card-body">
                <input type="text" class="form-control mt-2 mb-2" v-model="newBacteriaMapping"
                  placeholder="Adicionar nova opção">
                <button class="btn btn-primary mb-4" @click.prevent="addBacteriaMapping()">Adicionar opção</button>
                <div v-for="bacteria in mappings.bacteria" :key="bacteria" class="mb-3">
                  <label for="bacteria" class="form-label">{{ bacteria.bacteria }}</label>
                  <div v-for="option in new Set(mappings.species_col.concat(bacteria.mapping))" :key="option"
                    id="bacteria" class="form-check">
                    <input type="checkbox" class="form-check-input" :id="bacteria - option" :value="option"
                      v-model="bacteria.mapping" @change="toggleBacteriaMapping(option)">
                    <label class="form-check-label" :for="bacteria - option">{{ option }}</label>
                  </div>
                </div>
              </div>
            </div>
            <button type="submit" class="btn btn-primary w-100">Seguinte</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      currentStep: 1,
      steps: [1, 2, 3, 4],
      stepTitles: ["Carregar ficheiro", "Colunas gerais", "Antimicrobianos", "Codificação"],
      selectedFile: null,
      fileColumns: [],
      generalSelections: {},
      antimicrobials: {},
      mappings: {},
      newSpecimenMapping: '',
      newBacteriaMapping: '',
      newSusceptibilityMapping: '',
    };
  },
  computed: {
    progressPercentage() {
      return ((this.currentStep - 1) / (this.steps.length - 1)) * 100;
    },
  },
  methods: {
    handleFileUpload(event) {
      this.selectedFile = event.target.files[0];
    },
    goForward() {
      if (this.currentStep < this.steps.length) {
        this.currentStep++;
        window.scrollTo(0,0);
      } else {
        console.log('Wizard completed');
      }
    },
    addSusceptibilityMapping() {
      if (this.newSusceptibilityMapping && !this.mappings.susceptibility_col.includes(this.newSusceptibilityMapping)) {
        this.mappings.susceptibility_col.push(this.newSusceptibilityMapping);
        this.newSusceptibilityMapping = '';
      }
    },
    toggleSusceptibilityMapping(mapping) {
      const mappingIndex = this.mappings.susceptibility_col.indexOf(mapping);
      if (mappingIndex >= 0) {
        this.mappings.susceptibility_col.splice(mappingIndex, 1)
      } else {
        this.mappings.susceptibility_col.push(mapping)
      }
    },
    addSpecimenMapping() {
      if (this.newSpecimenMapping && !this.mappings.specimen_col.includes(this.newSpecimenMapping)) {
        this.mappings.specimen_col.push(this.newSpecimenMapping);
        this.newSpecimenMapping = '';
      }
    },
    toggleSpecimenMapping(mapping) {
      const mappingIndex = this.mappings.specimen_col.indexOf(mapping);
      if (mappingIndex >= 0) {
        this.mappings.specimen_col.splice(mappingIndex, 1)
      } else {
        this.mappings.specimen_col.push(mapping)
      }
    },
    addBacteriaMapping() {
      if (this.newBacteriaMapping && !this.mappings.species_col.includes(this.newBacteriaMapping)) {
        this.mappings.species_col.push(this.newBacteriaMapping);
        this.newBacteriaMapping = '';
      }
    },
    toggleBacteriaMapping(mapping) {
      const mappingIndex = this.mappings.species_col.indexOf(mapping);
      if (mappingIndex >= 0) {
        this.mappings.species_col.splice(mappingIndex, 1)
      } else {
        this.mappings.species_col.push(mapping)
      }
    },
    async uploadFile() {
      if (!this.selectedFile) {
        alert('Por favor seleciona um ficheiro');
        return;
      }
      const formData = new FormData();
      formData.append('file', this.selectedFile);

      try {
        const response = await axios.post('/config_file', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
        });
        this.fileColumns = response.data.sort();
        console.log('File uploaded successfully', response.data);
        this.fetchLastConfig()
        this.goForward()
      } catch (error) {
        alert('Ocorreu um erro, por favor verifique que escolheu um ficheiro excel com as colunas na primeira linha')
        console.error('File upload failed:', error);
      }
    },
    async fetchLastConfig() {
      try {
        const response = await axios.get('/last_config', {
          headers: {
            'Content-Type': 'application/json'
          }
        });
        this.generalSelections = response.data
      } catch (error) {
        console.error('Could not fetch last config from server:', error)
      }
    },
    async sendGeneralColumns() {
      try {
        const response = await axios.post('/general_columns', this.generalSelections, {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
        });
        console.log('Success', response.data)
        this.fetchAllAntimicrobials()
        this.goForward()
      } catch (error) {
        console.error('Error submitting form:', error);
      }
    },
    fetchAllAntimicrobials() {
      axios.get('/antimicrobial_mappings')
        .then(response => {
          this.antimicrobials = response.data.sort((first, second) => {
            if (first.group < second.group) return -1;
            if (first.group > second.group) return 1;
            if (first.name < second.name) return -1;
            if (first.name > second.name) return 1;
            return 0;
          });
          console.log(this.antimicrobials)
        })
        .catch(error => {
          console.error('Error fetching antimicrobials:', error)
        });
    },
    async sendAntimicrobialColumns() {
      try {
        const response = await axios.post('/antimicrobial_columns', this.antimicrobials, {
          headers: {
            'Content-Type': 'application/json'
          },
        });
        console.log('Success', response.data);
        await this.fetchMappings();
        this.goForward();
      } catch (error) {
        console.error('Error submitting form:', error);
      }
    },
    async fetchMappings() {
      const formData = new FormData();
      formData.append('file', this.selectedFile);
      try {
        const response = await axios.post('/mappings', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
        });
        this.mappings = response.data;
        console.log('Fetched mappings successfully', response.data);
      } catch (error) {
        alert('Ocorreu um erro ao tentar encontar o mapeamento das variáveis')
        console.error('File upload failed:', error);
      }
    },
    async sendMappings() {
      try {
        const response = await axios.post('/new_mappings', this.mappings, {
          headers: {
            'Content-Type': 'application/json'
          },
        });
        console.log('Success', response.data);
        this.$router.push('/');
      } catch(error) {
        alert('Ocorreu um erro ao fazer o mapeamento')
        console.error('Something went wrong with mapping', error)
      }
    },
  },
};
</script>

<style>
.circle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #e9ecef;
  color: black;
  line-height: 30px;
  display: inline-block;
}

.filled {
  background-color: #007bff;
  color: white;
}
</style>
