import { createApp } from 'vue'
import { createPinia } from 'pinia';
import App from './App.vue'
import router from './router'
import axios from 'axios'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

axios.defaults.withCredentials = true
axios.defaults.baseURL = process.env.VUE_APP_API_URL;

async function getCSRF() {
    return axios.get('/csrf')
        .then (response => {
            console.log('Fetched CSRF Token', response);
        })
        .catch (error => console.error('CSRF Token error', error));
}

function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        const cookies = document.cookie.split(';');
        for (let i= 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}

axios.interceptors.request.use(async (config) => {
    if (['post', 'put', 'delete', 'patch'].includes(config.method.toLowerCase())) {
        await getCSRF()
            .then(() => {
                const csrftoken = getCookie('csrftoken');
                config.headers['X-CSRFToken'] = csrftoken;
            })
            .catch((error) => {
                console.error('CSRF Token fetch error', error);
            });
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});

createApp(App).use(router).use(createPinia()).mount('#app');


