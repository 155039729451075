<template>
  <div class='container'>
    <div class="row justify-content-center">
      <div class="col-md-12 mt-2">
        <nav class="navbar navbar-expand-md bg-info-subtle shadow-sm">
          <div class="container-fluid">
            <a class="navbar-brand" href="#"><router-link style="text-decoration: inherit; color: inherit;"
                to="/">Brilliant TeAMS</router-link></a>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
              aria-label="Toggle navigation">
              <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
              <form class="d-flex mb-2 mb-lg-0" role="search">
                <input class="form-control me-2" type="search" placeholder="Número de amostra" aria-label="search"
                  v-model="searchQuery['identifier']">
                <button class="btn btn-outline-primary" href="#" @click.prevent="triggerSearch()">Procurar</button>
              </form>
              <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
                <li class="nav-item">
                  <a class="nav-link" href="#"><router-link style="text-decoration: inherit; color: inherit;"
                      to="/configurar">Configuração</router-link></a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#" @click.prevent="logout">Sair</a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </div>
    <div class="modal fade" id="searchModal" tabindex="-1" aria-labelledby="searchModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="searchModalLabel">Pesquisa de amostras</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" @click=closeModal aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <p v-if="searchObject.length === 0">Não foi encontrada nenhuma amostra.</p>
            <div v-else>
              <ul>
                <li v-for="(value, key) in filteredSearchObject" :key="key">
                  {{ key }}: {{ value }}
                </li>
                <li v-for="(value, key) in searchObject[modalIndex]['TSA']" :key="key">
                  {{ key }}: {{ value }}
                </li>
              </ul>
              <nav v-if="searchObject.length > 1">
                <ul class="pagination">
                  <li class="page-item" v-for="(item, index) in searchObject" :key="index"
                    :class="{ active: index === modalIndex }">
                    <a class="page-link" href="#" @click.prevent="setModalIndex(index)">{{ index + 1 }}</a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click=closeModal data-bs-dismiss="modal">Fechar</button>
          </div>
        </div>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-md-12 mt-3">
        <div class="card">
          <div class="card-header h5">
            Fonte de dados
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col">
                <div class="card">
                  <div class="card-body">
                    Atualização: <strong>{{ updateDate }}</strong>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="card">
                  <div class="card-body">
                    Utilizador: <strong>Dr. {{ updateUser }}</strong>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="card">
                  <div class="card-body">
                    Amostras novas: <strong>{{ newSamples }}</strong>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <div class="d-flex align-items-center">
              <div>
                <input type="file" ref="file" style="display: none" @change="handleFileUpload" />
                <button class="btn btn-primary" @click.prevent="triggerFileInput">
                  Atualizar base de dados
                </button>
              </div>
              <div v-if="isUploading" class="progress mx-2" style="width: 50%;">
                <div class="progress-bar" role="progressbar" style="width: 100%;" aria-valuenow="100" aria-valuemin="0"
                  aria-valuemax="100"></div>
              </div>
              <i v-if="uploadSuccess" class="bi bi-check-circle text-success mx-2"></i>
              <div v-if="uploadError" class="alert alert-danger mx-2 mb-0">
                {{ uploadError }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-md-12 mt-4">
        <div class="row">
          <div class="d-flex align-items-start">
            <div class="nav flex-column nav-pills me-3" id="v-pills-tab" role="tablist" aria-orientation="vertical">
              <button v-for="bacteria in bacteriaList" :class="['nav-link', { active: activeTab === bacteria.id }]"
                :id="'tab-' + bacteria.id" :key="bacteria.id" data-bs-toggle="pill" @click="setActiveTab(bacteria.id)"
                type="button" role="tab"><i>{{ bacteria.species }}</i></button>
            </div>
            <div class="tab-content w-100" id="v-pills-tabContent">
              <div v-for="bacteria in bacteriaList"
                :class="['tab-pane fade', { 'show active': activeTab === bacteria.id }]" :id="'pane-' + bacteria.id"
                :key="'pane-' + bacteria.id" role="tabpanel">
                <div class="input-group">
                  <button type="button" class="btn btn-secondary"> {{ astData[bacteria.id]['total'] }}</button>
                  <select v-model="selectedRequester" class="form-select"
                    @change="() => fetchASTData(bacteria, selectedRequester)">
                    <option v-for="requester in requesters" :value="requester" :key="requester">
                      {{ requester }}
                    </option>
                  </select>
                </div>
                <table class="table">
                  <thead>
                    <tr class="text-center">
                      <th class="col-6">Antimicrobiano</th>
                      <th class="col-2">S</th>
                      <th class="col-2">I</th>
                      <th class="col-2">R</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="antimicrobial in bacteriaAntimicrobials[bacteria.id]" :key="antimicrobial.id">
                      <td>{{ antimicrobial.name }}</td>
                      <td class="text-center">
                        <span :class="{ 'active-s': isActiveFilter(bacteria, antimicrobial.name, 'S') }"
                          class="p-2 rounded" @click="toggleFilter(bacteria, antimicrobial.name, 'S')">{{
                    getASTData(bacteria.id, 'S',
                      antimicrobial) }}</span>
                      </td>
                      <td class="text-center">
                        <span :class="{ 'active-i': isActiveFilter(bacteria, antimicrobial.name, 'I') }"
                          class="p-2 rounded" @click="toggleFilter(bacteria, antimicrobial.name, 'I')">{{
                    getASTData(bacteria.id, 'I',
                      antimicrobial) }}</span>
                      </td>
                      <td class="text-center">
                        <span :class="{ 'active-r': isActiveFilter(bacteria, antimicrobial.name, 'R') }"
                          class="p-2 rounded" @click="toggleFilter(bacteria, antimicrobial.name, 'R')">{{
                    getASTData(bacteria.id, 'R',
                      antimicrobial) }}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { useUserStore } from '@/store';
import * as bootstrap from 'bootstrap';

export default {
  data() {
    return {
      activeTab: null,
      currentUser: 'Não identificado',
      isUploading: false,
      uploadSuccess: false,
      uploadError: null,
      newSamples: 0,
      updateDate: 'dd-mm-aaaa',
      updateUser: 'Não identificado',
      bacteriaList: [],
      requesters: [],
      selectedRequester: [],
      bacteriaAntimicrobials: {},
      astData: {},
      activeFilters: [],
      modalIndex: 0,
      searchQuery: {},
      searchObject: [],
    };
  },
  methods: {
    async logout() {
      const userStore = useUserStore();
      console.log(userStore.user)
      try {
        await userStore.logout();
        this.$router.push('/login');
      } catch (error) {
        console.error('Error in logout:', error);
      }
    },
    openModal() {
      let myModal = new bootstrap.Modal(document.getElementById('searchModal'), {
        keyboard: false
      });
      myModal.show();
    },
    closeModal() {
      let myModal = new bootstrap.Modal(document.getElementById('searchModal'));
      this.modalIndex = 0;
      myModal.hide();
    },
    triggerSearch() {
      axios.post('/search', this.searchQuery, {
        headers: {
          'Content-Type': 'application/json'
        },
      })
        .then(response => {
          this.searchObject = response.data;
          console.log(this.searchObject)
          this.openModal()
        })
        .catch(error => {
          console.error('Error fetching samples:', error);
        });
    },
    setModalIndex(index) {
      this.modalIndex = index;
    },
    setActiveTab(tabId) {
      this.activeTab = tabId;
    },
    async fetchDataSource() {
      try {
        const response = await axios.get('/data_source');
        this.currentUser = response.data.current_user;
        this.newSamples = response.data.new_samples;
        this.updateDate = response.data.update_date;
        this.updateUser = response.data.update_user;
      } catch (error) {
        console.error('Error fetching info on data sources:', error);
      }
    },
    triggerFileInput() {
      this.$refs.file.click();
    },
    handleFileUpload(event) {
      const file = event.target.files[0];
      if (file) {
        this.uploadFile(file);
      }
    },
    async uploadFile(file) {
      const formData = new FormData();
      formData.append('file', file);
      this.isUploading = true;
      this.uploadSuccess = false;
      this.uploadError = null;

      try {
        const response = await axios.post('/upload_file', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
        });
        console.log('File uploaded successfully', response.data);
        this.fetchDataSource();
        this.fetchRequesters();
        this.fetchBacteria();
        this.uploadSuccess = true;
        setTimeout(() => {
          this.isUploading = false;
          this.uploadSuccess = false;
        }, 1000);
      } catch (error) {
        console.error('There was an error uploading the file', error);
        this.uploadError = 'Erro ao carregar ficheiro. Verifique o formato do ficheiro';
        this.isUploading = false;
      }
    },
    fetchBacteria() {
      this.fetchRequesters();
      axios.get('/bacteria')
        .then(response => {
          this.bacteriaList = response.data.sort((first, second) => {
            if (first.species < second.species) return -1;
            if (first.species > second.species) return 1;
            return 0;
          });
          this.bacteriaList.forEach(bacteria => {
            this.astData[bacteria.id] = { 'total': 'N/A' }
            this.fetchAntimicrobials(bacteria.id);
            this.fetchASTData(bacteria, this.selectedRequester)
          });
          console.log(this.selectedRequester)
          if (this.bacteriaList.length > 0) {
            this.activeTab = this.bacteriaList[0].id;
          }
        })
        .catch(error => {
          console.error('Error fetching bacteria:', error)
        });
    },
    fetchRequesters() {
      axios.get('/requesters')
        .then(response => {
          this.requesters = response.data;
          this.selectedRequester = this.requesters[0]
        })
        .catch(error => {
          console.error('Error fetching requesters:', error);
        });
    },
    fetchAntimicrobials(bacteriaId) {
      axios.get(`/bacteria-antimicrobials/${bacteriaId}`)
        .then(response => {
          this.bacteriaAntimicrobials[bacteriaId] = response.data;
        })
        .catch(error => {
          console.error('Error fetching antimicrobials:', error);
        });
    },
    fetchASTData(bacteria, requester) {
      axios.get(`/ast-data/?bacteriaId=${bacteria.id}&requester=${requester}&filters=${this.activeFilters}`)
        .then(response => {
          this.astData[bacteria.id] = response.data;
        })
        .catch(error => {
          console.error('Error fetching AST data:', error)
        });
    },
    toggleFilter(bacteria, antimicrobial, susceptibility) {
      const filterStr = `${bacteria.id}:${antimicrobial}:${susceptibility}`;
      const index = this.activeFilters.indexOf(filterStr);
      if (index === -1) {
        this.activeFilters.push(filterStr);
      } else {
        this.activeFilters.splice(index, 1);
      }
      this.fetchASTData(bacteria, this.selectedRequester);
    },
    isActiveFilter(bacteria, antimicrobial, susceptibility) {
      const filterString = `${bacteria.id}:${antimicrobial}:${susceptibility}`;
      return this.activeFilters.includes(filterString);
    },
    getASTData(bacteria, type, antimicrobial) {
      const data = this.astData[bacteria];
      if (data && data[antimicrobial.name]) {
        return data[antimicrobial.name][type];
      }
      return 'N/A';
    }
  },
  computed: {
    filteredSearchObject() {
      const keys = Object.keys(this.searchObject[this.modalIndex]);
      const filteredKeys = keys.slice(0, -1);
      let newObj = {};
      filteredKeys.forEach(key => {
        newObj[key] = this.searchObject[this.modalIndex][key];
      });
      return newObj;
    }
  },
  created() {
    this.fetchDataSource();
    this.fetchBacteria();
  }
};
</script>

<style>
.active-s {
  background-color: rgba(0, 255, 119, 0.386);
}

.active-i {
  background-color: rgba(191, 255, 0, 0.388);
}

.active-r {
  background-color: rgba(255, 0, 0, 0.319);
}
</style>