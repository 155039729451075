import { createRouter, createWebHistory } from 'vue-router'
import { useUserStore } from '@/store'
import HomeView from '../views/HomeView.vue'
import LoginView from '../views/LoginView.vue'
import WizardView from '../views/WizardView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: { requiresAuth: true }
  },
  {
    path: '/configurar',
    name: 'configurar',
    component: WizardView,
    meta: { requiresAuth: true}
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView,
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const userStore = useUserStore();
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const isAuthenticated = userStore.isAuthenticated;
  if (requiresAuth && !isAuthenticated) {
    next('/login');
  } else if (to.path == '/login' && isAuthenticated) {
    next('/');
  } else {
    next();
  }
})

export default router
