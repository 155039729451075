import { defineStore } from 'pinia';
import axios from 'axios';

export const useUserStore = defineStore('user', {
    state: () => ({
      user: null,
    }),
    getters: {
      isAuthenticated: (state) => !!state.user,
      userName: (state) => state.user ? state.user.name : '',
    },
    actions: {
      async login(credentials) {
        try {
          const response = await axios.post('/login', credentials);
          this.user = response.data;
        } catch (error) {
          console.error('Login error:', error);
          throw error;
        }
      },
      async logout() {
        try {
          await axios.post('/logout');
          this.user = null;
        } catch (error) {
          console.error('Logout error:', error);
        }
      },
    }
  });