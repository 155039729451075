<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-6">
        <div class="card mt-5">
          <div class="card-body">
            <h2 class="card-title text-center">TeAMS</h2>
            <form @submit.prevent="login">
              <div class="mb-3">
                <label for="username" class="form-label">Nome de utilizador</label>
                <input type="username" class="form-control" id="username" v-model="credentials.username" required>
              </div>
              <div class="mb-3">
                <label for="password" class="form-label">Password</label>
                <input type="password" class="form-control" id="password" v-model="credentials.password" required>
              </div>
              <button type="submit" class="btn btn-primary w-100">Entrar</button>
            </form>
          </div>
          <div class="card-footer alert alert-danger mb-0" v-if="errorMessage">
            {{ errorMessage }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import { useUserStore } from '@/store';

export default {
  data() {
    return {
      credentials: {
        username: '',
        password: '',
      },
      errorMessage: ''
    };
  },
  methods: {
    async login() {
      const userStore = useUserStore();
      try {
        await userStore.login(this.credentials)
        this.$router.push('/')
      } catch (error) {
        this.errorMessage = 'O login falhou. Tente novamente.';
      }
    },
  },
};
</script>
  